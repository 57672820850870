import React, { Component } from "react";
import svgMap from "svgmap";
import { withTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../Wow";

import "../../assets/scss/svgMap.scss";
var svgMapDataGPD = {
 
  data: {
    // gdp: {
    //   name: "GDP per capita",
    //   format: "{0} USD",
    //   thousandSeparator: ",",
    //   thresholdMax: 50000,
    //   thresholdMin: 1000,
    // },
    // change: {
    //   name: "Change to year before",
    //   format: "{0} %",
    // },
    // gdpAdjusted: {
    //   name: "Purchasing Power Parity",
    //   format: "{0} USD",
    //   thousandSeparator: ",",
    //   thresholdMax: 50000,
    //   thresholdMin: 1000,
    // },
    // changeAdjusted: {
    //   name: "Change to year before",
    //   format: "{0} %",
    // },
    dataCountry: {
      name: "",
      format: "",
    },
  },
  applyData: "dataCountry",
  values: {
    PL: { dataCountry: "" },
    DE: { dataCountry: "" },
    FR: { dataCountry: "" },
    AU: { dataCountry: "" },
    SA: { dataCountry: "" },
    RU: { dataCountry: "" },
  },
};
class About extends Component<any> {
  svgMap: any;

  componentDidMount() {
    if (!this.svgMap) {
      if (typeof window !== `undefined`) {
        var mySvgMap = new svgMap({
          targetElementID: "svgMap",
          noDataText: "",
          data: svgMapDataGPD,
        });

        this.svgMap = mySvgMap;
      }
    }
  }

  render() {
    const { t } = this.props;

    return (
      <ComponentWow>
        <div className="container-fluid py-5">
          <div className="container py-5">
            <div className="row align-items-end mb-4">
              <div className="col-lg-12">
                <h6 className="text-primary font-weight-semi-bold text-uppercase mb-3">
                  {t("about.map.title")}
                </h6>
                <h3 className="h1 section-title mb-3"> {t("about.map.desc")}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div id="svgMap"></div>
              </div>
            </div>
          </div>
        </div>
      </ComponentWow>
    );
  }
}

export default withTranslation()(About);
