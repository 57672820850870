import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../Wow";

const OurFactory = () => {
  const { t } = useTranslation();

  return (
    <ComponentWow>
      <div className="container-fluid bg-light py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-7 pt-lg-5 pb-3">
              {/* <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
              Why Choose Us
            </h6> */}
              <h3 className="h1 mb-4 section-title">
                {t("about.ourFactory.title")}
              </h3>
              <p className="mb-4">{t("about.ourFactory.desc")}</p>
            </div>
            <div className="col-lg-5" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100 rounded overflow-hidden">
                {/* <img
                className="position-absolute w-100 h-100"
                src="img/feature.jpg"
                style={{ objectFit: "cover" }}
              /> */}
                <div
                  className="Sirv"
                  data-src="https://terstela.sirv.com/Factory%20Image/Factory%20Image.spin"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default OurFactory;
