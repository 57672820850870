import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../../Wow";
import { CAROSEL_MARKET } from "../../../constants";

const Team = () => {
  const { t } = useTranslation();

  const renderOurTeam = () => {
    return CAROSEL_MARKET.map((val, index) => {
      return (
        <div
          className="d-flex flex-column text-center rounded overflow-hidden market-wrapper"
          key={`products-used-for-${index}`}
        >
          {/* <div className="position-relative">
            <div className="team-img">
              <img className="img-fluid w-100 Sirv" data-src={val.src} alt={val.name} />
            </div>
           
          </div> */}
          {/* <div className="d-flex flex-column bg-primary text-center py-4">
            <h5 className="font-weight-bold">{val.name}</h5>
            <p className="text-white m-0">{val.title}</p>
          </div> */}
          <div className="market-title d-flex text-left flex-column">
            <h5>{t(val.title)}</h5>
            <h6>
              {t(val.descTop)} <br /> {t(val.descBottom)}
            </h6>
          </div>

          <div className="market-body d-flex">
            <div>
              <p className="market-desc">{t(val.feat1)}</p>
              <p className="market-desc">{t(val.feat2)}</p>
              <p className="market-desc">{t(val.feat3)}</p>
              <p className="market-desc">{t(val.feat4)}</p>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                className="img-fluid Sirv image-market"
                data-src={val.src}
                alt={val.title}
              />
            </div>
            <div>
              <p className="market-desc">{t(val.feat5)}</p>
              <p className="market-desc">{t(val.feat6)}</p>
              <p className="market-desc">{t(val.feat7)}</p>
              <p className="market-desc">{t(val.feat8)}</p>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <ComponentWow>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="owl-service owl-carousel market-carousel position-relative owl-theme">
                {renderOurTeam()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default Team;
