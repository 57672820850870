import React, { useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ProductHeader from "../components/Products/Header";
import Layout from "../templates/layout";
import AboutCard from "../components/LandingPage/About";
import OurFactory from "../components/OurFactory";
import Map from "../components/Map";
import Team from "../components/LandingPage/Team";
import ModalVideo from "../components/LandingPage/ModalVideo";

const About = () => {
  const [isVideoLoad, setIsVideoLoad] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t("meta.about.title")} | ${t("meta.title")}`}</title>
        <meta content={t("meta.about.keyword")} name="keywords" />
        <meta content={t("meta.about.desc")} name="description" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout currentLink="about" description={t("meta.about.desc")}>
        <>
          <ProductHeader title={t("menu.about")} />
          <AboutCard
            onPlay={() => {
              setIsVideoLoad(true);
            }}
            isAbout
          />
          <ModalVideo isShow={isVideoLoad} />

          <OurFactory />
          <Map />
          <Team />
        </>
      </Layout>
    </>
  );
};

export default About;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
